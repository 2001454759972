// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "r_q5 d_gs d_cp";
export var heroHeaderCenter = "r_gt d_gt d_cp d_ds";
export var heroHeaderRight = "r_gv d_gv d_cp d_dt";
export var heroParagraphLeft = "r_q6 d_gp d_cs";
export var heroParagraphCenter = "r_gq d_gq d_cs d_ds";
export var heroParagraphRight = "r_gr d_gr d_cs d_dt";
export var heroBtnWrapperLeft = "r_q7 d_dZ d_dY d_s d_bw d_bC";
export var heroBtnWrapperCenter = "r_q8 d_d0 d_dY d_s d_bw d_bz";
export var heroBtnWrapperRight = "r_q9 d_d1 d_dY d_s d_bw d_bD";
export var overlayBtnWrapper = "r_rb d_gn d_X d_4 d_5 d_6 d_bk d_b8";
export var design4 = "r_rc d_gm d_X d_4 d_5 d_bk";
export var heroExceptionSmall = "r_rd x_rd";
export var heroExceptionNormal = "r_rf x_rf";
export var heroExceptionLarge = "r_rg x_rg";
export var Title1Small = "r_rh x_rh x_rV x_rW";
export var Title1Normal = "r_rj x_rj x_rV x_rX";
export var Title1Large = "r_rk x_rk x_rV x_rY";
export var BodySmall = "r_rl x_rl x_rV x_sc";
export var BodyNormal = "r_rm x_rm x_rV x_sd";
export var BodyLarge = "r_rn x_rn x_rV x_sf";